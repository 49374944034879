import { v4 as uuidv4 } from 'uuid';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import styles from './amazon.module.css'
import Header from '../../components/sections/header/Header.js'
import Container from '../../components/container/Container.js';
import Button from '../../components/button-centered/Button.js';
import Footer from '../../components/sections/footer/Footer.js';




function Amazon() {
    const uuid = 'a'.concat(uuidv4().replace(/-/g, ""));
    window.scrollTo(0, 0);
    const transformation =
        `import mgp
import json

@mgp.transformation
def book_ratings(messages: mgp.Messages 
            )-> mgp.Record(query=str, parameters=mgp.Nullable[mgp.Map]):
    result_queries = []

    for i in range(messages.total_messages()):
        message = messages.message_at(i)
        books_dict = json.loads(message.payload().decode('utf8'))
        result_queries.append(
            mgp.Record(
                query=("MERGE (b:Book {id: $bookId, title: $title}) "
                        "MERGE (u:User {id: $userId}) "
                        "WITH u, b "
                        "CREATE (u)-[r:RATED {rating: ToFloat($rating), timestamp: $timestamp}]->(b)"),
                parameters={
                    "bookId": books_dict["bookId"],
                    "userId": books_dict["userId"],
                    "rating": books_dict["rating"],
                    "timestamp": books_dict["timestamp"],
                    "title": books_dict["title"]
                    }))

    return result_queries`;
    const queryCreateStream =
        `CREATE KAFKA STREAM amazon_stream
TOPICS book-ratings
TRANSFORM amazon_books.book_ratings
CONSUMER_GROUP ${uuid}
BOOTSTRAP_SERVERS 'get.awesomedata.stream:9093'
CREDENTIALS {'sasl.username':'public',
                'sasl.password':'public',
                'security.protocol':'SASL_PLAINTEXT',
                'sasl.mechanism':'PLAIN'};`;
    const queryStartStream = `START STREAM amazon_stream;`;
    const basicQuery =
        `MATCH p=(n)-[r]-(m)
RETURN p LIMIT 100;`;
    const customStyle = { 'border-radius': '8px', 'padding-left': '40px', 'padding-right': '40px', 'box-shadow': '0 5px 10px 3px rgb(0 0 0 / 30%)' }
    const messageSchema =
        `{
    "bookId": "1501172786"
    "userId": "A3PHMW8UUZ338X",
    "rating": "3.0",
    "timestamp": "1529366400",
    "title": "Full Disclosure: A Novel"
}`

    return (
        <div>
            <Header title="Amazon" subtitle="Connect to a stream of Amazon books ratings and explore it!"></Header>
            <Container>
                <h2 className={styles.title}>Connect to the stream</h2>
                <div>
                    <p className={styles.description}>
                        Amazon data stream streams new book ratings. <br />
                        The connection details are:
                    </p>
                    <p className={styles.description}>
                        <b>Server address:</b> <code className={styles.codeLine}>get.awesomedata.stream:9093</code> <br />
                        <b>Topic:</b> <code className={styles.codeLine}>book-ratings</code> <br />
                        <b>Consumer group:</b> <code className={styles.codeLine}>{uuid}</code> <br />
                        <b>Additional Kafka parameters:</b>
                        <ul className={styles.listDescription}>
                            <li>sasl.username: <code className={styles.codeLine}>'public'</code></li>
                            <li>sasl.password: <code className={styles.codeLine}>'public'</code></li>
                            <li>security.protocol: <code className={styles.codeLine}>'SASL_PLAINTEXT'</code></li>
                            <li>sasl.mechanism: <code className={styles.codeLine}>'PLAIN'</code></li>
                        </ul>
                    </p>
                </div>
                <div>
                    <p className={styles.description}>In the Amazon data stream, each JSON message represents a new book rating:</p>
                </div>
                <div className={styles.codeSnippet}>
                    <SyntaxHighlighter language='json' style={atomDark} customStyle={customStyle}>{messageSchema}</SyntaxHighlighter>
                </div>
            </Container>
            <Container>
                <h2 className={styles.title}>Connect with Memgraph</h2>
                <p className={styles.description}>Take full advantage of your streaming data with Memgraph graph application platform to unlock a whole new world of capabilities and insights.</p>
                <h3 className={styles.subtitle}>Step 1: Create a transformation module</h3>
                <div >
                    <p className={styles.description}>
                        In order to connect Memgraph to a data stream, create a transformation module that will instruct Memgraph on how to transform the incoming messages and consume them correctly.
                    </p>
                    <p className={styles.description}>
                        In the Amazon transformation module called <code className={styles.codeLine}>amazon_books</code>, the defined Cypher query will be triggered by each JSON message and map the elements of the message as a graph object:
                    </p>
                </div>
                <div className={styles.codeSnippet}>
                    <SyntaxHighlighter language='python' style={atomDark} customStyle={customStyle}>{transformation}</SyntaxHighlighter>
                </div>
                <p className={styles.description}>In Memgraph, the transformation module will create Book and User nodes with their corresponding properties and connect them with the RATED relationship:</p>
                <div className={styles.graphImg}>
                    <img src={require('./img/amazon.svg').default} alt='amazonImg' />
                </div>
                <p className={styles.description}>
                    If you are using <b><a className={styles.links} href='https://memgraph.com/docs/memgraph-lab'>Memgraph Lab</a></b> you can create transformation modules in the <b>Query Modules</b> section. <br></br>
                    Otherwise, you need to:
                    <ol className={styles.description}>
                        <li>Create a Python or a shared library file (module) with the above code.</li>
                        <li>Save the file into the Memgraph's <code className={styles.codeLine}>query_modules</code> directory (default: <code className={styles.codeLine}>/usr/lib/memgraph/query_modules</code>).</li>
                        <li>Load the file into Memgraph either on startup (automatically) or by running a <code className={styles.codeLine}>CALL mg.load_all();</code> query.</li>
                    </ol>
                    For more information check out our <a className={styles.links} href='https://memgraph.com/docs/memgraph/reference-guide/streams/transformation-modules'>guide on transformation modules</a>.
                </p>
            </Container>
            <Container>
                <h3 className={styles.subtitle}>Step 2: Create a Stream</h3>
                <p className={styles.description}>
                    Run the following query to create a stream:
                </p>
                <div className={styles.codeSnippet}>
                    <SyntaxHighlighter language='cypher' style={atomDark} customStyle={customStyle}>{queryCreateStream}</SyntaxHighlighter>
                </div>
                <p className={styles.description}>
                    If you are using <b>Memgraph Lab</b>, follow the <b>Stream</b> &rarr; + <b>Add New Stream</b> wizard using the corresponding data.
                    You can change the consumer group by editing an already created stream.
                    If you need additional help with creating a stream in Memgraph Lab, take a look at our <a className={styles.links} href='https://memgraph.com/docs/memgraph/import-data/data-streams/manage-streams-lab'>tutorial</a>.
                </p>
            </Container>
            <Container>
                <h3 className={styles.subtitle}>Step 3: Start a Stream</h3>
                <p className={styles.description}>
                    Run the following query to start the stream:
                </p>
                <div className={styles.codeSnippet}>
                    <SyntaxHighlighter language='cypher' style={atomDark} customStyle={customStyle}>{queryStartStream}</SyntaxHighlighter>
                </div>
                <p className={styles.description}>
                    In <b>Memgraph Lab</b> you can start the stream with a push of the <b>Connect to stream</b> button.
                </p>
            </Container>
            <Container>
                <h3 className={styles.subtitle}>Step 4: Query the database</h3>
                <div >
                    <p className={styles.description}>
                        That's it! You just connected to a real-time data source with Memgraph.<br />
                        Run a query to check the data coming in:
                    </p>
                </div>
                <div className={styles.codeSnippet}>
                    <SyntaxHighlighter language='cypher' style={atomDark} customStyle={customStyle}>{basicQuery}</SyntaxHighlighter>
                </div>
            </Container>

            <Container>
                <h2 className={styles.title}>What to do with all this data?</h2>
                <div>
                    <p className={styles.description}>
                        If you are new to the graph world and want to know what you can do with all this data at your fingertips - do not fret!
                        We've prepared queries that utilize the most powerful graph algorithms to gain every last bit of insight that data can provide. <a className={styles.links} href='https://memgraph.com/blog/how-to-build-a-real-time-book-recommendation-system-on-amazon-books-dataset'>Let the querying begin!</a>
                    </p>
                </div>
            </Container>
            <Button></Button>
            <Footer></Footer>
        </div>
    );
}

export default Amazon;
