import React from 'react';
import styles from './dataset-box.module.css';
import { Link } from "react-router-dom"


export default function DatasetBox(props) {
    const {
        title,
        subtitle,
        url,
        urlText
    } = props;

    return (
        <Link to={url} style={{ "text-decoration": "none" }}>
            <div className={styles.datasetBox}>
                <div className={styles.datasetBox__title}>{title}</div>
                <div className={styles.datasetBox__subtitle}>{subtitle}</div>
                <div className={styles.linkRightArrow} >{urlText} &rarr;&nbsp;</div>
            </div>
        </Link>

    );
}