import React, { Component } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import MovieLens from "./pages/movielens/MovieLens"
import Amazon from "./pages/amazon/Amazon";
import ArtBlocks from "./pages/artblocks/ArtBlocks";
import Github from "./pages/github/Github";

class App extends Component {
    render() {
        return (
            <HashRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/movielens" element={<MovieLens />} />
                    <Route path="/amazon" element={<Amazon />} />
                    <Route path="/artblocks" element={<ArtBlocks />} />
                    <Route path="/github" element={<Github />} />
                </Routes>
            </HashRouter>
        );
    }
}

export default App