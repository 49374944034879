import { v4 as uuidv4 } from 'uuid';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import styles from './movielens.module.css'
import Header from '../../components/sections/header/Header.js'
import Container from '../../components/container/Container.js';
import Button from '../../components/button-centered/Button.js';
import Footer from '../../components/sections/footer/Footer.js';



function MovieLens() {
    const uuid = 'a'.concat(uuidv4().replace(/-/g, ""));
    window.scrollTo(0, 0);
    const transformation = `import mgp
import json

@mgp.transformation
def rating(messages: mgp.Messages
                ) -> mgp.Record(query=str, parameters=mgp.Nullable[mgp.Map]):
    result_queries = []

    for i in range(messages.total_messages()):
        message = messages.message_at(i)
        movie_dict = json.loads(message.payload().decode('utf8'))
        result_queries.append(
            mgp.Record(
                query=("MERGE (u:User {id: $userId}) "
                        "MERGE (m:Movie {id: $movieId, title: $title}) "
                        "WITH u, m "
                        "UNWIND $genres as genre "
                        "MERGE (m)-[:OF_GENRE]->(:Genre {name: genre}) "
                        "MERGE (u)-[r:RATED {rating: ToFloat($rating), timestamp: $timestamp}]->(m)"),
                parameters={
                    "userId": movie_dict["userId"],
                    "movieId": movie_dict["movie"]["movieId"],
                    "title": movie_dict["movie"]["title"],
                    "genres": movie_dict["movie"]["genres"],
                    "rating": movie_dict["rating"],
                    "timestamp": movie_dict["timestamp"]}))

    return result_queries`;
    const queryCreateStream = `CREATE KAFKA STREAM movielens_stream
TOPICS ratings
TRANSFORM movielens.rating
CONSUMER_GROUP ${uuid}
BOOTSTRAP_SERVERS 'get.awesomedata.stream:9093'
CREDENTIALS {'sasl.username':'public',
                'sasl.password':'public',
                'security.protocol':'SASL_PLAINTEXT',
                'sasl.mechanism':'PLAIN'};`;
    const queryStartStream = `START STREAM movielens_stream;`;
    const basicQuery = `MATCH p=(n)-[r]-(m)
RETURN p LIMIT 100;`;
    const customStyle = { 'border-radius': '8px', 'padding-left': '40px', 'padding-right': '40px', 'box-shadow': '0 5px 10px 3px rgb(0 0 0 / 30%)' }
    const messageSchema = `{
    "userId": "112",
    "movie": {
        "movieId": "4993",   
        "title": "Lord of the Rings: The Fellowship of the Ring, The (2001)",   
        "genres": ["Adventure", "Fantasy"]
    },
    "rating": "5",
    "timestamp": "1442535783"
}`;

    return (
        <div>
            <Header title="MovieLens" subtitle="Connect to a stream of MovieLens ratings and explore it!"></Header>
            <Container>
                <h2 className={styles.title}>Connect to the stream</h2>
                <div>
                    <p className={styles.description}>
                        MovieLens data stream streams new movie ratings. <br />
                        The connection details are:
                    </p>
                    <p className={styles.description}>
                        <b>Server address:</b> <code className={styles.codeLine}>get.awesomedata.stream:9093</code> <br />
                        <b>Topic:</b> <code className={styles.codeLine}>ratings</code> <br />
                        <b>Consumer group:</b> <code className={styles.codeLine}>{uuid}</code> <br />
                        <b>Additional Kafka parameters:</b>
                        <ul className={styles.listDescription}>
                            <li>sasl.username: <code className={styles.codeLine}>'public'</code></li>
                            <li>sasl.password: <code className={styles.codeLine}>'public'</code></li>
                            <li>security.protocol: <code className={styles.codeLine}>'SASL_PLAINTEXT'</code></li>
                            <li>sasl.mechanism: <code className={styles.codeLine}>'PLAIN'</code></li>
                        </ul>
                    </p>
                </div>
                <div>
                    <p className={styles.description}>In the MovieLens data stream, each JSON message represents a new movie rating:</p>
                </div>
                <div className={styles.codeSnippet}>
                    <SyntaxHighlighter language='json' style={atomDark} customStyle={customStyle}>{messageSchema}</SyntaxHighlighter>
                </div>
            </Container>
            <Container>
                <h2 className={styles.title}>Connect with Memgraph</h2>
                <p className={styles.description}>Take full advantage of your streaming data with Memgraph graph application platform to unlock a whole new world of capabilities and insights.</p>
                <h3 className={styles.subtitle}>Step 1: Create a transformation module</h3>
                <div >
                    <p className={styles.description}>
                        In order to connect Memgraph to a data stream, create a transformation module that will instruct Memgraph on how to transform the incoming messages and consume them correctly.
                    </p>
                    <p className={styles.description}>
                        In the MovieLens transformation module called <code className={styles.codeLine}>movielens</code>, the defined Cypher query will be triggered by each JSON message and map the elements of the message as a graph object:
                    </p>
                </div>
                <div className={styles.codeSnippet}>
                    <SyntaxHighlighter language='python' style={atomDark} customStyle={customStyle}>{transformation}</SyntaxHighlighter>
                </div>
                <p className={styles.description}>In Memgraph, the transformation module will create Movie, User, and Genre nodes with their corresponding properties and connect them with the OF_GENRE and RATED relationships:</p>
                <div className={styles.graphImg}>
                    <img src={require('./img/movielens.svg').default} alt='amazonImg' />
                </div>
                <p className={styles.description}>
                    If you are using <b><a className={styles.links} href='https://memgraph.com/docs/memgraph-lab'>Memgraph Lab</a></b> you can create transformation modules in the <b>Query Modules</b> section. <br></br>
                    Otherwise, you need to:
                    <ol className={styles.description}>
                        <li>Create a Python or a shared library file (module) with the above code.</li>
                        <li>Save the file into the Memgraph's <code className={styles.codeLine}>query_modules</code> directory (default: <code className={styles.codeLine}>/usr/lib/memgraph/query_modules</code>).</li>
                        <li>Load the file into Memgraph either on startup (automatically) or by running a <code className={styles.codeLine}>CALL mg.load_all();</code> query.</li>
                    </ol>
                    For more information check out our <a className={styles.links} href='https://memgraph.com/docs/memgraph/reference-guide/streams/transformation-modules'>guide on transformation modules</a>.
                </p>
            </Container>
            <Container>
                <h3 className={styles.subtitle}>Step 2: Create a Stream</h3>
                <p className={styles.description}>
                    Run the following query to create a stream:
                </p>
                <div className={styles.codeSnippet}>
                    <SyntaxHighlighter language='cypher' style={atomDark} customStyle={customStyle}>{queryCreateStream}</SyntaxHighlighter>
                </div>
                <p className={styles.description}>
                    If you are using <b>Memgraph Lab</b>, follow the <b>Stream</b> &rarr; + <b>Add New Stream</b> wizard using the corresponding data.
                    You can change the consumer group by editing an already created stream.
                    If you need additional help with creating a stream in Memgraph Lab, take a look at our <a className={styles.links} href='https://memgraph.com/docs/memgraph/import-data/data-streams/manage-streams-lab'>tutorial</a>.
                </p>
            </Container>
            <Container>
                <h3 className={styles.subtitle}>Step 3: Start a Stream</h3>
                <p className={styles.description}>
                    Run the following query to start the stream:
                </p>
                <div className={styles.codeSnippet}>
                    <SyntaxHighlighter language='cypher' style={atomDark} customStyle={customStyle}>{queryStartStream}</SyntaxHighlighter>
                </div>
                <p className={styles.description}>
                    In <b>Memgraph Lab</b> you can start the stream with a push of the <b>Connect to stream</b> button.
                </p>
            </Container>
            <Container>
                <h3 className={styles.subtitle}>Step 4: Query the database</h3>
                <div >
                    <p className={styles.description}>
                        That's it! You just connected to a real-time data source with Memgraph.<br />
                        Run a query to check the data coming in:
                    </p>
                </div>
                <div className={styles.codeSnippet}>
                    <SyntaxHighlighter language='cypher' style={atomDark} customStyle={customStyle}>{basicQuery}</SyntaxHighlighter>
                </div>
            </Container>

            <Container>
                <h2 className={styles.title}>What to do with all this data?</h2>
                <div>
                    <p className={styles.description}>
                        If you are new to the graph world and want to know what you can do with all this data at your fingertips - do not fret!
                        We've prepared queries that utilize the most powerful graph algorithms to gain every last bit of insight that data can provide. <a className={styles.links} href='https://memgraph.com/blog/how-to-analyze-a-streaming-dataset-of-movie-ratings-using-custom-query-modules'>Let the querying begin!</a>
                    </p>
                </div>
            </Container>
            <Button></Button>
            <Footer></Footer>
        </div>
    );
}

export default MovieLens;
