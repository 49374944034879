import Button from '../../button/Button';
import styles from './header.module.css';

function Header(props) {
  const title = props.title
  if (title !== 'Awesome Data Stream') {
    return (
      <header className={styles.header}>
        <img src={require('./img/stream-bg2.svg').default} alt='streamImg' />
        <h1>
          {props.title}
        </h1>
        <p  >
          {props.subtitle}
          <br />
          {props.subtitle2}
        </p>
        <Button></Button>
      </header>
    );
  }
  else {
    return (
      <header className={styles.header}>
        <img src={require('./img/stream-bg2.svg').default} alt='streamImg' />
        <h1>
          {props.title}
        </h1>
        <p  >
          {props.subtitle}
          <br />
          {props.subtitle2}
        </p>
      </header>
    );
  }
}

export default Header;