import React from 'react';
import styles from './datasets.module.css';
import DatasetBox from "../../../components/dataset-box/DatasetBox";
import Container from "../../container/Container";

export default function Datasets() {

    const datasets = [
        {
            title: 'Amazon',
            subtitle: 'Explore the Amazon books dataset by connecting to the streaming book ratings.',
            urlText: 'Connect to the stream',
            url: '/amazon'
        },
        {
            title: 'GitHub',
            subtitle: 'Explore the GitHub commits dataset by connecting to the streaming GitHub commits.',
            urlText: 'Connect to the stream',
            url: '/github'
        },
        {
            title: 'MovieLens',
            subtitle: 'Explore the MovieLens dataset by connecting to the streaming movie ratings.',
            urlText: 'Connect to the stream',
            url: '/movielens'
        },


        {
            title: 'Art Blocks',
            subtitle: 'Explore the Art Blocks sales dataset by connecting to the streaming Art Blocks sales.',
            urlText: 'Connect to the stream',
            url: '/artblocks'
        },
    ]

    return (
        <Container >
            <div className={styles.datasets}>
                {datasets.map((dataset, i) => (
                    <div key={i} className={styles.dataset__item}>
                        <DatasetBox title={dataset.title} subtitle={dataset.subtitle} url={dataset.url} urlText={dataset.urlText} />
                    </div>
                ))}
            </div>
        </Container>
    );
}