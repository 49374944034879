import styles from './footer.module.css';

function Footer(props) {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerHead}>
                <a href="https://github.com/memgraph" target="_blank" rel="noopener noreferrer">
                    <img src={require('./img/github-dark-mode.svg').default} alt='github' />
                </a>
                <a href="https://discourse.memgraph.com/" target="_blank" rel="noopener noreferrer">
                    <img src={require('./img/dev-forum-dark-mode.svg').default} alt='discourse' />
                </a>
                <a href="https://discord.gg/memgraph" target="_blank" rel="noopener noreferrer">
                    <img src={require('./img/discord-dark-mode.svg').default} alt='discord' />
                </a>
                <a href="https://stackoverflow.com/questions/tagged/memgraphdb" target="_blank" rel="noopener noreferrer">
                    <img src={require('./img/stack-overflow-dark-mode.svg').default} alt='stackoverflow' />
                </a>
            </div>
            <div className={styles.footerBottom}>
                <div className={styles.footerLogo}>
                    <a href="https://memgraph.com" target="_blank" rel="noopener noreferrer">
                        <img src={require('./img/Memgraph-logo-white-rgb.png')} alt='Memgraph Logo'></img>
                    </a>
                </div>
                <div className={styles.footerCopyright}>Copyright © {new Date().getFullYear()} Awesome Streams, Powered by Memgraph</div>
            </div>
        </footer>
    );
}

export default Footer;