import Header from '../../components/sections/header/Header.js'
import Datasets from '../../components/sections/datasets/Datasets';
import Footer from '../../components/sections/footer/Footer.js';
import styles from './home.module.css'
import Container from '../../components/container/Container.js';

function Home() {
  return (
    <div>
      <Header title="Awesome Data Stream" subtitle="Do you need an awesome data stream? We've actually got several." subtitle2="They are both awesome and simple - just connect and explore!"></Header>
      <Container >
        <div className={styles.homePage}>
          <h2 className={styles.title}>Streaming Datasets</h2>
          <p className={styles.description}>Connect to one of the available streaming datasets from your analytics platform to get insights from the data. <br /><br />
            Every dataset page contains general connection information and detailed instructions on how to stream data into Memgraph and explore dozens of existing graph algorithms directly from Cypher or easily extend Cypher with your own custom procedures to solve complex problems by using the power of graphs!</p>
        </div>
        <Datasets></Datasets>
      </Container>
      <Footer></Footer>
    </div>
  );
}

export default Home;
